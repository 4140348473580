<template>
  <b-card
    v-if="userData && total"
    class="watermark-container full-height"
  >
    <b-card-text />
    <b-card-text><h2 class="mb-1">
      {{ $t('home.welcome.title') }} {{ userData.firt_name }} {{ userData.middle_name }} {{ userData.surname_1 }} {{ userData.surname_2 }}
    </h2> </b-card-text>
    <b-card-text>{{ $t('home.welcome.attendet') }} {{ month }}: {{ total.total_attended }} </b-card-text>
    <b-card-text>{{ $t('home.welcome.notattendet') }} {{ month }}: {{ total.total_not_attended }} </b-card-text>
    <b-card-text><h2 class="mb-1">
      {{ $t('home.welcome.total') }} {{ month }}: {{ total.total }}
    </h2></b-card-text>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import store from '@/store'
import moment from 'moment'
import HomeStoreModule from './storeModule'

export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
      userData: undefined,
      total: undefined,
      avatar: '',
      month: '',
      HOME_STORE_MODULE_NAME: 'home-user',
    }
  },
  mounted() {
    if (!store.hasModule(this.HOME_STORE_MODULE_NAME)) store.registerModule(this.HOME_STORE_MODULE_NAME, HomeStoreModule)
    this.fetchUser()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.HOME_STORE_MODULE_NAME)) store.unregisterModule(this.HOME_STORE_MODULE_NAME)
  },
  methods: {
    fetchUser() {
      moment.locale('es')
      this.month = moment().format('MMMM YYYY')
      store.dispatch(`${this.HOME_STORE_MODULE_NAME}/fetchCurrentUser`)
        .then(response => {
          this.userData = response.data
          store.dispatch(`${this.HOME_STORE_MODULE_NAME}/fetchTotalCasesDate`, { doctor_id: this.userData.id })
            .then(res => {
              this.total = res.data
            })
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.userData)
            this.userData = undefined
          }
        })
    },
  },
}
</script>

<style>
.watermark-container {
  position: relative;
}

.watermark-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('~@/assets/images/logo/logo-meraki.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.3;
  pointer-events: none;
}

.full-height {
  height: 80vh !important;
}
</style>
