import axios from '@axios'
import useJwt from '@core/auth/jwt/useJwt'

const { jwt } = useJwt(axios, {})

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCurrentUser(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/user/current')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTotalCasesDate(ctx, { doctor_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/process/case_date/doctor_total/${doctor_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
 
}
